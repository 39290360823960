.marker {
    /* position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%); */
}
marker:hover {
    z-index: 1;
}

.pin {
    width: 22px;
    height: 30px;
    /* border-radius: 50% 50% 50% 0; */
    /* background: #00cae9; */

    position: absolute;
    /* transform: rotate(-45deg); */
    left: 50%;
    top: 50%;
    margin: -14px 0 0 -16px;
    z-index: 100;
  }
  .pin:after {
    /* content: "";
    width: 7px;
    height: 7px;
    margin: 6px 0 0 6px;
    background: #e6e6e6;
    position: absolute;
    border-radius: 50%; */
  }
  
  .bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
  }
  
  .pulse {
    background: #0583F2;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
  }
  .pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px #0583F2;
    animation-delay: 1.1s;
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
  
    50% {
      opacity: 1;
    }
  
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
  
  @keyframes bounce {
    0% {
      opacity: 0;
      transform: translateY(-2000px) rotate(0deg);
    }
  
    60% {
      opacity: 1;
      transform: translateY(30px) rotate(0deg);
    }
  
    80% {
      transform: translateY(-10px) rotate(0deg);
    }
  
    100% {
      transform: translateY(0) rotate(0deg);
    }
  }
   
.marker{
  font-size: 12px;
  background-color: #fff;
  background: #fff;
  padding: 0px;
  width:auto;
  display: inline-block;
  border-radius: 7px;
  border: 2px solid #1F57A0;
  background: #FFF;
  min-width:200px; 
  /* height:100px; */
  z-index: 9999!important;
  position: relative;
}
.marker .markerTitle{
  background-color: #1F57A0;
  display: inline-flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  
}
.marker .markerTitle h4{
  padding: 5px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* padding-top: 10px; */
}
.marker .markerTitle svg{
  margin-top: -10px;
  height: 11px;
  width: 8px;
}

.marker .markerText{
  padding: 10px;
  /* height: 80px; */
}

.marker .markerText p{ 
  color: #19274C;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;

}