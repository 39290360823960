@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700&display=swap');:root {
    --primary-blue: #0583F2;
    --primary-orange: #FA6900;
    --secondary-darkblue: #19274C;
    --secondary-blue: #1F57A0;
    --secondary-light: #EEF0F4;
    --ui-green:#4D9B09;
    --ui-red:#F23005;
  }
   
  .blue{color: #0583F2;}

  .headline{
    font-family: 'Barlow', sans-serif;
    font-weight: 300;
    font-size: 36px;
    line-height: 44px;
    color: #1F57A0;
  }
  *{
    font-family: 'Barlow', sans-serif;
  }

  .camp-noshow{
    display: none!important;
  }