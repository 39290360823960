@use "./global.scss";
div {
    // border:1px solid red;
}
body {
    color: #1f57a0;
}

.notification {
    background: #1f57a0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 15px;
    p {
        color: #fff;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 0px;
    }
}
p strong {
    color: #0583f2;
    font-family: Barlow;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.loading {
    margin-top: 275px;
    text-align: center;
    min-height: 50vh;
}
@media screen and (max-width: 767px) {
    .loading {
        margin-top: 125px;
        text-align: center;
    }
}

.loading h1 {
    font-family: Barlow;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #1f57a0;
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.main {
    margin: 0px;
    padding: 0px;
}
.main-content {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 140px;
    margin-top: 175px;
}
.noalert {
    margin-top: 125px;
}
.contact-content {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
    margin-top: 180px;
}
.resources-content {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
    margin-top: 60px;
}
.news-header {
    margin-top: 100px;
    background: #1f57a0;
    color: #fff;
    padding-top: 49px;
    padding-bottom: 49px;
}
@media screen and (max-width: 767px) {
    .news-header {
        margin-top: 0px;
    }
}

.news-header .news-header-copy {
    padding-left: 20px;
    padding-right: 20px;
}
.news-header .news-header-copy h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 44px;
    /* or 122% */
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    color: #ffffff;
}
.news-header .news-header-copy p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    color: #ffffff;
}
.news-nav {
    display: inline-flex;
}
.news-nav li {
    list-style-type: none;
    margin-right: 20px;
}
.news-nav li a {
    color: #1f57a0;
    font-weight: 600;
    text-decoration: none;
}
.news-nav li a:hover {
    text-decoration: underline;
}
.news-nav li .newsActive {
    text-decoration: underline;
}

@media screen and (max-width: 767px) {
    .contact-content {
        margin-top: 65px;
        margin-bottom: 65px;
    }
    .resources-content {
        margin-top: 65px;
        margin-bottom: 65px;
    }
    .contactWrapper {
        margin-bottom: 65px;
    }
}

.mapDiv {
    height: 100vh;
    width: 100%;
    padding-bottom: 189px;
}
.noalert .mapDiv {
    padding-bottom: 139px;
}

.mapsection {
    position: fixed;
    right: 0;
    top: 149px;
    padding-right: 0px !important;
    // z-index: -1;
}
.noalert div .mapsection {
    top: 100px;
}
.topSection {
    // background: #fff;
    z-index: 1;
    position: fixed;
    width: 100%;
    left: 0px;
    right: 0px;
    top: 0px;
}
header {
    z-index: 1;
    background: #fff;
}

.page-title {
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 43px;
    color: #1f57a0;
}
.mainfilter {
    position: fixed;
    width: inherit;
}

.mobileFilterSearch {
    display: inline-flex;
    width: 100%;
}
.filterTitleDistance {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 5px;
    line-height: 19px;
    // margin-bottom: 20px;
    color: #19274c;
}
.distance {
    border: 1px Solid#19274C;
    border-radius: 2px;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    height: 35px;
    padding: 0px;
    max-width: 180px;
}
.filter-wrapper-top {
    padding-bottom: 30px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    div {
        display: flex;
        flex-direction: column;
    }
    .specialneeds {
        margin-top: 20px;
        .checkLabel {
            justify-content: left;
        }
    }
}
@media screen and (max-width: 767px) {
    .distance {
        max-width: 128px;
        border-color: #d9d9d9;
    }
    .filter-wrapper-top {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0px 18px;
        margin-bottom: 6px;
        div {
            flex-direction: row;
        }
        .specialneeds {
            margin-top: 0px;
        }
    }
    .filterTitleDistance {
        padding-right: 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #19274c;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
}
.checkLabel {
    display: inline-flex;
    justify-content: center;
    flex-direction: row;
    padding-right: 10px;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #19274c;
}
@media screen and (max-width: 767px) {
    .checkLabel {
        padding-right: 30px;
    }
}

.checkLabel input[type="checkbox"] {
    // -webkit-appearance: none;
    // appearance: none;
    background-color: #fff;
    margin: 0;
    background: #eef0f4;
    border: 0px solid #1f57a0;
    border-radius: 2px;
    width: 25px;
    height: 25px;
    margin-right: 6px;
    display: inline-flex;
    padding: 0px;
    // outline: 2px solid #1F57A0;
    // margin-left: 5%;
}

p {
    // font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #19274c;
}
.header {
    padding: 16px;
}
.pageHeader {
    padding-right: 0px;
}

// .header .cta-submit{
//   font-family: 'Barlow';
//   font-style: normal;
//   font-weight: 700;
//   font-size: 16px;
//   line-height: 19px;
//   display: flex;
//   align-items: center;
//   text-align: center;
//   color:#ffffff;
//   background: #1F57A0;
//   border:solid 1px #1F57A0;
//   border-radius: 7px;
//   padding: 10px 16px;
// }
.header .cta-submit {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #fff;
    border: 2px solid #eef0f4;
    border-radius: 7px;
    background: #1f57a0;
    padding: 12px 12px;
    margin-bottom: 10px;
    text-decoration: none;
    text-align: center;
}

.header .cta-submit:hover {
    //pointer-events: none;
    background: #0583f2;
}
.btndescription {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.btndescription p {
    font-size: 12px;
}
.btndescriptionPrice {
    display: flex;
    justify-content: left;
    align-items: start;
    margin-top: 10px;
}

.btndescriptionPrice p {
    font-size: 15px;
    padding-left: 6px;
    padding-top: 6px;
}
.btndescriptionPrice p span {
    font-weight: bold;
}

.buttonsection {
    margin-right: 30px;
    padding-top: 15px;
    text-align: center;
    z-index: 3;
    p {
        margin-bottom: 0px;
    }
}

.logo {
    width: 197px;
}
.logo a {
    width: 100%;
}
.logo img {
    width: 100%;
    height: auto;
}
.wrapper > * {
    padding: 20px;
}

// form styles
label {
    color: #19274c;
    // padding: 30px;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
}

.page-contact form label {
    color: #fff;
    width: 100%;
    flex-direction: column;
    display: flex;
    padding-bottom: 20px;
}
.page-contact form label input {
    // width: 262px;
    height: 35px;
    background: #eef0f4;
    border: 2px solid #eef0f4;
    text-decoration-line: underline;
    color: #8d8d8d;
    text-decoration: none;
}
// #submitCampOne label input{
//   margin-top: 8px;
//   line-height: 45px!important;
//   padding: 0px!important;
//   padding-left: 15px!important;
//  }
//  #submitCamp label input{
//   margin-top: 8px;
//   line-height: 18px!important;
//   line-height: 45px!important;
//   padding: 0px!important;
//   padding-left: 15px!important;
//  }

.subbuttonOrange {
    border-radius: 7px;
    background: #f26120;
    color: #fff;
    text-align: center;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 40px;
    text-decoration: none;
}
.campSubWrapper {
}
.campSubBox {
    border: 1px solid #0583f2;
    background: #eef0f4;
    position: relative;
}
.campSubBox:hover {
    background: #cfdcf4;
}
.campSubBox .campsubcontainer {
    padding: 36px;
    padding-bottom: 145px;
}
.orgSubBox {
    border: 1px solid #0583f2;
    background: #eef0f4;
    position: relative;
}
.orgSubBox:hover {
    background: #cfdcf4;
}
.orgSubBox .campsubcontainer {
    padding: 36px;
    padding-bottom: 145px;
}
.subBtnContainer {
    position: absolute;
    bottom: 36px;
}
.campsubcontainer h2 {
    color: #1f57a0;
    font-family: Barlow;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.campsubcontainer p {
    color: #19274c;
    font-family: Barlow;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding-top: 20px;
    padding-bottom: 20px;
}
.campsubcontainer ol {
    padding-left: 1.3rem;
}
.campsubcontainer ol li {
    color: #19274c;
    font-family: Barlow;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.spanCost {
    color: #1f57a0;
    font-family: Barlow;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.spanFree {
    color: #fa6900;
    font-family: Barlow;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.subLinkBtn {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 992px) {
    .orgSubBox .campsubcontainer {
        padding-bottom: 165px;
    }
    .campSubBox .campsubcontainer {
        padding-bottom: 165px;
    }
}

@media screen and (max-width: 767px) {
    .campsubcontainer {
        //padding-left: 1.5rem;
        //padding-right: 1.5rem;
        border: 1px solid #0583f2;
        background: #eef0f4;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .campSubBox,
    .orgSubBox {
        //   border: 1px solid #0583F2;
        //   background: #EEF0F4;
        //   margin-bottom: 10px;
        border: 0px solid #0583f2;
        background: #fff;
        margin: 0px;
    }
    .orgSubBox .campsubcontainer {
        padding-bottom: 40px;
    }
    .campSubBox .campsubcontainer {
        padding-bottom: 40px;
    }
    .campSubWrapper {
        padding-top: 30px;
    }

    .subBtnContainer {
        position: relative;
        bottom: 0px;
    }
}

#submitCampOne label input {
    // width: 262px;
}
#submitCampOne label input[type="radio"] {
    // width: 20px;
}

input {
    background: #ffffff;
    height: 50px;
}
textarea.form-control {
    border: solid 2px var(--secondary-darkblue);
}

.page-contact .submitFormWrapper {
    background-color: var(--secondary-darkblue);
    padding: 40px;
    //  width: 776px;

    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    //  flex: 1 0 100%;
    flex-wrap: wrap;
    //  margin-top: calc(var(--bs-gutter-y) * -1);
    //  margin-right: calc(var(--bs-gutter-x)/ -2);
    //  margin-left: calc(var(--bs-gutter-x)/ -2);
}
.page-contact form input {
}
.page-contact form input:disabled {
    opacity: 50%;
}
.page-contact form input:hover:enabled {
    background: #eef0f4;
    border: 2px solid #eef0f4;
    text-decoration-line: underline;
    color: #19274c;
}
#finalBtn {
    background: #fa6900;
    color: #fff;
    border-color: #fa6900;
}
#finalBtn:enabled {
    background: #fa6900;
    border-color: #fa6900;
    color: #fff;
}
#finalBtn:disabled {
    background: #8d8d8d;
    color: #eef0f4;
    border-color: #8d8d8d;
}
#finalBtn:hover:enabled {
    background: #0583f2;
    border-color: #0583f2;
    text-decoration: underline;
}
.formBtnWrapper {
    display: flex;
    justify-content: center;
}
.formInputBtn {
    color: #fff;
    background-color: #1f57a0;
    border-radius: 7px;
    border: 0px;
    width: 147px;
    display: flex;
    justify-content: center;
    padding: 0px;
}
.formInputBtn:disabled {
    background-color: #dfe0e3;
}
.formThankyou {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.formThankyou h3 {
    color: #4d9b09;
    padding-bottom: 10px;
}

.search {
    display: inline-flex;
}

input {
    width: 100%;
    height: 50px;
    padding: 15px;
    margin: 4px;
    box-sizing: border-box;
    padding-left: 12px;
    display: inline;
}
select {
    width: 100%;
    height: 50px;
    padding: 15px;
    margin: 4px;
    box-sizing: border-box;
    padding-left: 36px;
    display: inline;
}
input.campsearch {
    padding-left: 18px;
}

.Icon-inside {
    position: relative;
}

.Icon-inside i {
    position: absolute;
    left: 16px;
    top: 17px;
    padding: 9px 9px;
    background-image: url("marker.svg");
    background-repeat: no-repeat;
    background-size: contain;
}
.input-field {
    border: none;
}

.Icon-outside {
    position: relative;
}

.Icon-outside i {
    position: absolute;
    margin-left: -6px;
    top: 10px;
    padding: 17px 15px;
    // color:#30A3F1;
    // background:black;
}

#camp {
    width: 560px;
    height: 52px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #19274c;
}

#location {
    width: 300px;
    height: 52px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #19274c;
}
#location input {
    padding-left: 36px;
}

@media screen and (max-width: 1440px) {
    #camp {
        width: 360px;
    }
}

@media screen and (max-width: 1199px) {
    #camp {
        width: 220px;
    }
    #location {
        width: 220px;
    }
}

#location-resource input {
    padding-left: 36px;
}
// .search-mobile{display: none;}
.logo {
    grid-area: logo;
}
.searchbox {
    grid-area: searchbox;
    display: flex;
}
.navBox {
    justify-content: end;
    align-items: center;
}
.subbutton {
    grid-area: subbutton;
    display: inline-flex;
    justify-content: end;
}
.navWrapper {
    display: inline-flex;
    padding-bottom: 6px;
}
.navWrapper a {
    margin-right: 40px;
    color: #1f57a0;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    text-decoration: none;
    z-index: 3;
    @media (min-width: 991px) and (max-width: 1199px) {
        margin-right: 20px;
    }
}
.navWrapper a:hover {
    text-decoration: underline;
}

.header {
    text-decoration: none;
    display: inline-grid;
    // grid-template-columns: 245px 1fr 1fr 130px;
    gap: 20px;
    grid-template-areas: "logo searchbox subbutton";
    width: 100%;
}

.filterBtn {
    display: none;
}
@media screen and (min-width: 767px) and (max-width: 829px) {
    .header {
        gap: 20px;
    }
    #location {
        width: 180px;
    }
    #camp {
        width: 180px;
    }
}
@media screen and (max-width: 767px) {
    // .search-mobile{
    //   display: block;
    //   margin-bottom: 20px;
    //   padding-right: calc(var(--bs-gutter-x) * .5);
    //   padding-left: calc(var(--bs-gutter-x) * .5);}
    .filterwrapper {
        padding: 0px;
        padding-bottom: 8px;
        border-bottom: 1px solid #d9d9d9;
    }
    .filterBtnWrapper {
        display: inline-flex;
        width: 100%;
    }
    .filterBtn {
        display: block;
    }

    .search {
        display: block;
    }
    .subbutton {
        justify-content: right;
        display: flex;
        // display: none;
    }
    .page-header {
        height: auto;
    }
    .page-header .header {
        display: grid;
    }
    .header {
        grid-template-areas:
            "logo logo subbutton subbutton"
            "searchbox searchbox searchbox searchbox";
        padding-bottom: 6px;
    }
    #camp {
        width: 100%;
        margin-bottom: 20px;
    }
    #location {
        width: 100%;
    }
}

.page-contact {
    flex-grow: 1;
}
.page-header {
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 30px;
}
.submitButtonMobile {
    display: none;
}

.mobileMenuShow {
    display: inline-flex;
}
@media screen and (min-width: 991px) {
    .header-open {
        transition: background-color 0.25s linear;
    }
    .header-open {
        background-color: #eef0f4;
    }

    .navWrapper {
        // position: fixed;
        // top: 20px;
        // right: -250px;
        // z-index: 0;
        // // width: 250px;
        // // height: 100%;
        // overflow-x: hidden;
        // transition: all 1.5s ease;
        // right: 0;
        // position: absolute;
        // right: 185px;
        animation: fadeOut 0.75s;
    }
    .navWrapper.navWrapperOpen {
        animation: fadeIn 0.75s;
        transition: color 0.25s linear;
        // animation: slide 0.5s forwards
        // right: 100%;
        // transform: translate(100%, 0);
    }
    @keyframes slide {
        100% {
            right: 200px;
        }
    }
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
@media screen and (max-width: 991px) {
    .mobileMenu {
        display: none;
    }
    .mobileMenuShow {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 92px;
        width: 100%;
        height: calc(100vh - 92px);
        background-color: #eef0f4;
        // bottom: -92px;
    }
    .mobileMenuShow {
        overflow: hidden;
    }
    .navBox {
        justify-content: center;
    }
    .navWrapper {
        display: flex;
        padding-bottom: 12px;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding-top: 45px;
    }
    .navWrapper a {
        margin-right: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
    }
    .header-open .header {
        padding-right: 0px;
    }
    .buttonsection {
        margin-right: 0px;
    }
}
// .page-header .header {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   align-items: center;

// }
.menuBtn {
    display: none;
}
.subbuttonDesktop {
    display: flex;
}
@media screen and (max-width: 991px) {
    .menuBtn {
        display: flex;
        padding-right: 16px;
    }
    .subbuttonDesktop {
        display: none;
    }
}
.page-header ul {
    display: flex;
    order: 1;
    width: 100%;
    margin-top: 15px;
}

@media screen and (min-width: 550px) {
    .page-header ul {
        width: auto;
        margin-top: 0;
    }

    .page-header .cta-submit {
        order: 1;
    }
}

.page-contact {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    min-height: 350px;
    background: #ffffff;
    background-blend-mode: luminosity;
    color: var(--secondary-darkblue);
    margin: 0 auto;
    margin-bottom: 50px;
}
.page-contact {
    margin-top: 180px;
}

.page-contact div {
    // max-width: 500px;
}

@media screen and (min-width: 768px) {
    .page-contact {
        // padding-left: 90px;
    }
}

.footerwrapper {
    width: 100%;
    margin: 0px;
    padding: 0px;
    background-color: #ffffff;
}
.page-footer {
    width: 100%;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column-reverse;
    height: 40px;
    justify-content: space-around;
    border-top: 1px solid #d9d9d9;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    background-color: #ffffff;
    z-index: 2;
}
.footer-right {
    display: inline-flex;
    justify-content: flex-end;
}

.footer-left {
    display: inline-flex;
    justify-content: flex-start;
}
@media (max-width: 767px) {
    .page-footer {
        width: 100%;
        margin: 0px;
        padding: 0px;
        display: flex;
        flex-direction: column-reverse;
        height: 40px;
        justify-content: space-around;
        border-top: 1px solid #d9d9d9;
        position: fixed;
        bottom: 0;
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #ffffff;
        z-index: 2;
    }
    .footer-right {
        display: inline-flex;
        justify-content: flex-end;
        padding-bottom: 14px;
    }

    .footer-left {
        display: inline-flex;
        justify-content: flex-start;
        padding-bottom: 40px;
    }
}

.page-footer ul {
    display: flex;
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.page-footer a {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #19274c;
    margin-right: 40px;
    text-decoration: none;
}
.page-footer a:hover {
    text-decoration: underline;
    color: #fa6900;
}

@media (max-width: 992px) {
    .page-footer a {
        margin-right: 20px;
    }
}

@media (max-width: 767px) {
    .footer-left {
        justify-content: center;
    }
    .footer-right {
        justify-content: center;
    }
    .footerWrapper {
        flex-direction: inherit;
    }
}

.col-6 {
    flex: 0 0 auto;
    width: 50%;
}
.col-12 {
    flex: 0 0 auto;
    width: 100%;
}

.formbtn {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #eef0f4;
    border: 2px solid #eef0f4;
    border-radius: 7px;
    background: #19274c;
    padding: 0px 20px;
    margin: 0px auto;
    width: 115px;
    text-align: center;
    justify-content: center;
}
.campSubmitButton {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 40px;
}

.mapWrapper {
    height: calc(100vh - 200px);
    width: 100%;
}

#map {
    width: 100%;
    height: 80vh;
}
header {
    height: 100px;
}
.campcard,
.campcardPromo {
    cursor: pointer;
}

.campcard {
    padding: 11px;
    border-bottom: 1px solid #d9d9d9;
    color: #19274c;
    margin-bottom: 10px;
    margin-top: 4px;
}
.promo .promoCard {
    background-color: #eef0f4;
    border-radius: 8px;
    border-bottom: none;
    padding-left: 0px;
    padding-right: 5px;
}

// CARD
.cardTitle {
    // font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #0583f2;
    margin-bottom: 12px;
    padding-right: 30px;
    span {
        font-weight: 400;
        color: #19274c;
    }
}

// PROMO
.campcardPromo p,
.campcard p,
.campcardLocation {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #19274c;
    margin-bottom: 4px;
}

.campcardPromo p span,
.campcardLocation span,
.campcard p span {
    color: #1f57a0;
    font-weight: 700;
}

.campcardPromo .weekCost,
.campcard .weekCost {
    font-size: 12px;
}
.proplogo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    // margin-left: 11px;
    padding: 10px;
    width: 100%;
    padding: 5px;
    border: 1px #c8d1e1 solid;
}
.proplogo img {
    height: auto;
    width: 100%;
}
.cardlocation {
    width: 20px;
    height: 20px;
    padding-right: 6px;
    margin-top: -4px;
}
.virtualCardImg {
    padding-top: 12px;
    padding-bottom: 12px;
}
.cardVirtual {
    width: 123px;
}
.campcardPromo .campcost,
.campcard .campcost {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #0583f2;
}
.campcardPromo .carddistance,
.campcard .carddistance {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #19274c;
    margin-bottom: 10px !important;
}

.promodescription {
    padding-top: 0px;
    padding-bottom: 10px;
}

.campcardPromo .promodescriptiontext,
.campcard .promodescriptiontext {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #19274c;
    margin-top: 8px;
}
.campcardPromo .promodescription,
.campcard .promodescription {
    position: relative;
    margin-bottom: 12px;
}
.campcardPromo .promodescription .promotedtext,
.campcard .promodescription .promotedtext {
    position: absolute !important;
    right: 12px;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    color: #1f57a0;
}
.rightCard {
    text-align: right;
}

.onepromo {
    grid-area: onepromo;
    height: 133px;
    width: 163px;
    background-color: #ffffff;
}
.twopromo {
    grid-area: twopromo;
    margin-left: 0px;
    margin-top: 6px;
}
.threepromo {
    grid-area: threepromo;
    position: relative;
}
.fourpromo {
    grid-area: fourpromo;
}

.one {
    grid-area: one;
}
.two {
    grid-area: two;
}
.three {
    grid-area: three;
}
.promo {
    margin-bottom: 10px;
}
.campcardPromo {
    text-decoration: none;
    display: grid;
    grid-template-columns: 165px 1fr 1fr 100px;
    gap: 4px;
    grid-template-areas:
        "onepromo twopromo twopromo threepromo"
        "fourpromo fourpromo fourpromo fourpromo";
    padding: 15px;
    // grid-auto-rows: minmax(100px, auto);
    margin-bottom: 12px;
}

@media screen and (max-width: 1199px) {
    .campcardPromo {
        grid-template-areas:
            "onepromo onepromo threepromo threepromo"
            "twopromo twopromo twopromo twopromo"
            "fourpromo fourpromo fourpromo fourpromo";
    }
}

.campcard {
    text-decoration: none;
    display: grid;
    // grid-template-columns: 160px 1fr 1fr 100px;
    gap: 4px;
    grid-template-areas:
        "one one one three"
        "two two two two";
    padding: 10px;
    padding-bottom: 26px;
    // grid-auto-rows: minmax(100px, auto);
}

@media screen and (max-width: 1199px) {
    .campcard {
        grid-template-areas:
            "one one three three"
            "two two two two";
    }
}

.testmarker {
    background-color: #fa6900;
    padding: 5px;
    width: 60px;
}

.expand {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #19274c;
    background-color: #fff;
    border: 0px solid #fff;
    float: right;
    margin-top: -20px;
    padding: 13px;
    background: #fff;
    position: relative;
    z-index: 100;
    margin-top: 10px;
    right: 55px;
}

// FILTER
.filter-wrapper {
    padding-right: 40px;
}
.filterTitle {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 10px;
    line-height: 19px;
    // margin-bottom: 20px;
    color: #19274c;
}
@media (max-width: 767px) {
    .filterTitle {
        font-size: 20px;
    }
}

.filterSection {
    padding-bottom: 0px;
    display: block;
}
@media (max-width: 767px) {
    .twopromo {
        margin-left: 0px;
    }
    .twopromo .cardTitle {
        margin-top: 8px;
    }
}

@media (max-width: 767px) {
    .filterTitle {
        font-size: 20px;
    }
    .filterSection {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
    .filterSection input {
        height: 36px;
        width: 128px;
    }
    .filterSectionAge {
        display: flex;
        flex-direction: row;
    }
    .filterSectionAge input {
        height: 36px;
        width: 75px;
    }
}
.filterSectionAge .filterSection {
    display: flex;
    flex-direction: column;
    width: 50%;
}
.filterSectionAge .filterSection .checkLabel {
    justify-content: left;
}
@media (min-width: 767px) {
    .filterSectionAge .filterSection {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .filterSectionAge .specialneeds {
        padding-top: 20px;
    }
}
.filter {
    background: #eef0f4;
    border: 1px solid rgba(31, 87, 160, 0.25);
    border-radius: 2px;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #19274c;
    height: 28px;
    padding: 0px !important;
    padding-left: 10px !important;
}
.startdate {
    //margin-bottom: 10px;
}

.age {
    background: #eef0f4;
    border: 1px solid #1f57a0;
    border-radius: 2px;
    width: 64px;
    // height: 28px;
    min-height: 2.2em;
    padding-left: 32px !important;
}

.ageLabel {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #19274c;
    display: block;
    width: 100%;
    padding-bottom: 12px;
}
.ageLabel span {
    font-style: normal;
    font-weight: 400;
}
.typeLabel {
    padding-top: 20px;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #19274c;
    display: block;
    width: 100%;
    padding-bottom: 12px;
}
.typeLabel span {
    font-style: normal;
    font-weight: 400;
}

.distanceLabel {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: block;
    color: #19274c;
    width: 100%;
}
.distanceLabel span {
    font-style: normal;
    font-weight: 400;
}

.costLabel {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: block;
    color: #19274c;
    width: 100%;
}
.costLabel span {
    font-style: normal;
    font-weight: 400;
}

input[type="range"] {
    display: block;
    -webkit-appearance: none;
    background-color: #d9d9d9;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    margin: 0 auto;
    outline: 0;
    padding: 2px;
}
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: #ffffff;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #19274c;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
​ input[type="range"]::-webkit-slider-thumb:hover {
    background-color: white;
    border: 2px solid #e74c3c;
}
input[type="range"]::-webkit-slider-thumb:active {
    transform: scale(1.6);
}

input[type="radio"] {
    height: 13px;
    width: 13px;
    margin-right: 8px;
    // margin-left: 30px;
}
input[type="radio"].right {
    margin-left: 30px;
}

//breadcrumb
.breadcrumbSection {
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    // margin-left: 10px;
    margin-bottom: 10px;
    padding-top: 0px;
}
.breadcrumbSection p {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #19274c;
    display: block;
    margin-bottom: 0.25rem;
}
@media (max-width: 767px) {
    .breadcrumbSection {
        padding-top: 14px;
    }
    .breadcrumbSection p {
        display: none;
    }
}
.breadcrumbSection h3 {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 43px;
    display: flex;
    align-items: center;
    display: block;
    color: #1f57a0;
}
.campFoundWrapper {
    position: relative;
    display: none;
}
.campFound {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1f57a0;
    margin-bottom: 20px;
    display: block;
}
.campFound span {
    color: #e74c3c;
}
.campFoundMobile {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1f57a0;
    margin-top: 2px;
    text-align: center;
    margin-bottom: 4px;
    margin-top: 32px;
}
.campFoundMobile span {
    color: #e74c3c;
}
.filterDirections {
    text-align: center;
    font-family: "Barlow";
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #19274c;
    margin-top: 5px;
    margin-bottom: 0px;
    display: none;
}
@media (max-width: 767px) {
    .filterDirections {
        display: block;
    }
}
.arrow {
    height: 38px;
    width: auto;
    margin-right: 10px;
    margin-right: 10px;
    position: absolute;
    left: 36px;
    top: -10px;
}

.clearAll {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .campFoundWrapper {
        display: block;
    }
    .campFound {
        display: none;
    }
}

.campcardPromo .camp-date,
.campcard .camp-date {
    margin-top: 0px;
}
.registration {
    font-size: 14px;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
}
.registration .blue {
    color: #1f57a0;
}
.registration .green {
    color: #4d9b09;
}
@media (max-width: 480px) {
    .logo {
        width: auto;
        width: 150px;
    }
    .header .cta-submit {
        font-size: 13px;
    }
    .campcard p,
    .campcardPromo p {
        font-size: 18px;
    }
    .campcardPromo .cardlocation,
    .campcardPromo .cardlocation {
        font-size: 17px;
    }
    .cardTitle {
    }
    .campcardPromo .carddistance,
    .campcard .carddistance {
        font-size: 15px;
    }
    .campcardPromo .camp-date,
    .campcard .camp-date {
        font-size: 17px;
        margin-top: 15px;
    }
    .campcardPromo .camp-age,
    .campcardPromo .camp-age {
        font-size: 17px;
    }
    .campcost {
    }

    .campcardPromo .promodescriptiontext,
    .campcard .promodescriptiontext {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 12px;
    }
}

@media (max-width: 767px) {
    .topSection {
        position: relative;
        border-top: 5px solid #1f57a0;
    }
    .mainfilter {
        position: relative;
    }
    .main-content {
        margin-top: 0px;
    }
    .noalert {
        margin-top: 12px;
    }
    .page-footer {
        position: relative;
    }
}

@media (max-width: 992px) {
    .mapsection {
        display: none;
    }
}

.filterCloseBtn {
    display: none;
}
@media (max-width: 768px) {
    .filterCloseBtn {
        display: block;
        background-color: #f5f5f5 !important;
        margin-bottom: 20px;
    }
    .page-header {
        border: 0px;
    }
}
// add filers
.filterHide {
    // display: none;
    opacity: 1;
    height: auto;
}
@media (max-width: 768px) {
    .filterHide {
        // display: none;
        opacity: 0;
        transition: all 0.5s ease-in-out;
        transform-origin: top;

        // transition: opacity 1s;
        height: 0;
        overflow: hidden;
    }
}
@media (min-width: 768px) {
    .filterShow {
        opacity: 1;
        height: auto;
        height: auto;
        transition: opacity 1s;
        background-color: none;
        padding-right: 40px;
        box-shadow: none;
    }
}
@media (max-width: 768px) {
    .filterShow {
        // display: block;
        transition: all 0.5s ease-in-out;
        opacity: 1;
        height: 408px;
        transition: opacity 1s;
        background-color: #f5f5f5;
        padding: 26px;
        // margin-bottom: 20px;
        box-shadow: inset 0px 0px 12px rgba(0, 0, 0, 0.15);
    }
}

.radioLabel {
    padding-top: 3px;
}
.formRadio {
    padding-top: 0px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.formRadio span {
    font-weight: 400;
}
.page-submit form .formRadio input {
    margin-top: 0px;
}

.submitNotes {
    color: #fff;
    font-size: 12px;
    text-align: center;
}

.filterTitleMobile {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}
.filterTitleMobile button {
    background: #fff;
    border: 0px;
}
.page-header {
    margin-bottom: 0px;
}
.filterBtn {
    background: #f5f5f5;
    padding: 13px;
    border: 1px solid #d9d9d9;
}
.filterIcon {
    width: 22px;
}

@media (max-width: 992px) {
    #location,
    #camp {
        box-shadow: none;
    }
}

#contact-form {
    margin-top: 46px;
    padding-bottom: 200px;
}
#contact-form fieldset .formInput {
    margin-bottom: 10px;
}

.pageContent p {
    line-height: 24px;
    font-size: 20px;
    font-weight: 400;
    font-family: "Barlow", sans-serif;
    color: #19274c;
}
.pageContent h1,
.pageContent h2,
.pageContent h3,
.pageContent h4,
.pageContent h5,
.pageContent h6 {
    margin-top: 2rem;
}
.pageContent hr {
    margin: 1.5rem 0;
}
.pageContent ol {
    list-style-type: lower-alpha;
}
.pageContent ol li {
    margin-top: 0.25rem;
}

.pageContentContact p {
    line-height: 24px;
    font-size: 20px;
    font-weight: 400;
    font-family: "Barlow", sans-serif;
    color: #19274c;
}
.pageContentContact p strong {
    line-height: 24px;
    font-size: 20px;
    font-weight: 600;
    font-family: "Barlow", sans-serif;
    color: #0583f2;
}
.pageContentContact h1,
.pageContentContact h2,
.pageContentContact h3,
.pageContentContact h4,
.pageContentContact h5,
.pageContentContact h6 {
    margin-top: 1rem;
    font-family: "Barlow", sans-serif;
    color: #1f57a0;
}
.pageContentContact hr {
    margin: 1.5rem 0;
}
.pageContentContact ol {
    list-style-type: lower-alpha;
}
.pageContentContact ol li {
    margin-top: 0.25rem;
    font-family: "Barlow", sans-serif;
    color: #1f57a0;
}
#contact-form input {
    width: 100%;
    height: 50px;
    padding: 15px;
    margin: 4px;
    box-sizing: border-box;
    padding-left: 15px;
    display: inline;
    font-family: "Barlow", sans-serif;
    border: solid 2px var(--secondary-darkblue);
}

.formBtnWrapper {
    display: flex;
    justify-content: center;
}
#contact-form input.formInputBtn {
    border-radius: 7px;
    border: 0px;
    width: 147px;
    display: flex;
    justify-content: center;
    padding: 0px;
}
#contactBtn {
    background: #fa6900;
    color: #fff;
    border-color: #fa6900;
}
#contactBtn:enabled {
    background: #fa6900;
    border-color: #fa6900;
    color: #fff;
}
#contactBtn:disabled {
    background: #8d8d8d;
    color: #eef0f4;
    border-color: #8d8d8d;
}
#contactBtn:hover:enabled {
    background: #0583f2;
    border-color: #0583f2;
    text-decoration: underline;
}

.formThankyou {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.formThankyou h3 {
    color: #4d9b09;
    padding-bottom: 10px;
}

//FAQS
.faqs {
    width: 100%;
    // max-width: 768px;
    margin: 0 auto;
    padding: 15px 0px;
    font-family: "Barlow";
}
.faqs a {
    text-decoration: none;
}

.faqs .faq {
    margin: 15px 0px;
    padding: 15px 0px;
    background: #fff;
    border-radius: 10px;
    font-family: "Barlow";
    // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.faqs .faq .faq-question {
    position: relative;
    font-size: 20px;
    padding-right: 80px;
    transition: all 0.4s ease;
    font-family: "Barlow";
    color: #0583f2;
    font-weight: 700;
}
.faqs .faq .faq-question * {
    font-size: 20px;
    padding-right: 80px;
    font-family: "Barlow";
    color: #0583f2;
    font-weight: 700;
}
.faqs .faq .faq-question::after {
    content: "+";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transition: all 0.2s ease;
}
.faqs .faq .faq-question:hover {
    cursor: pointer;
}

.faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease;
    font-family: "Barlow";
    color: #19274c;
}

.faqs .faq.open .faq-question {
    margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
    content: "-";
}

.faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
}

.daysCheckbox {
    display: inline-flex;
    justify-content: space-around;
}

.dayWrapper {
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-right: 10px;
}
.dayWrapper input {
}
.dayWrapper label {
    padding: 0px;
    margin: 0px;
}
.thankyou {
    text-align: center;
}
.thankyou h1,
.thankyou p,
.thankyou a {
    font-family: "Barlow", sans-serif;
}

.page-contact h1,
.page-contact h3,
.page-contact p {
    font-family: "Barlow", sans-serif;
}
.page-contact h3 {
    color: #1f57a0;
    font-size: 1.5rem;
}

.campSubmitError {
    text-align: center;
}
.campSubmitError p {
    color: #f23005;
    font-weight: 700;
    font-size: 16px;
}
.submitBtnWrapper {
    margin-bottom: 10px;
}

input.startdate,
input.enddate {
    display: block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 2.2em;
    border-radius: 2px;
    font-family: "Barlow";
    background: #eef0f4;
    max-width: 180px;
    border: 1px solid #1f57a0;
    color: #1f57a0;
    border-radius: 2px;
    line-height: 32px !important;
}
input.startdate::placeholder {
    color: #1f57a0;
}
input.enddate::placeholder {
    color: #1f57a0;
}
.form-control {
    color: #1f57a0;
}

@media (max-width: 767px) {
    .dateSection {
        width: 50%;
    }
    input.startdate {
        min-width: 100%;
    }
    input.enddate {
        min-width: 100%;
    }
}

.datelabel {
    padding-top: 16px;
    padding-bottom: 0px;
    color: #19274c;
    display: block;
    font-family: "Barlow";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 10px;
}

// @media (max-width: 767px) { // mobile devices
//   @supports (-webkit-overflow-scrolling: touch) { //only safari
//     input[type="date"]:before {
//       color: #000;
//       content: attr(placeholder);
//     }

//     input[type="date"].filled:before {
//       color: #000;
//       content: "" !important;
//     }

//     input[type="date"]:not(.filled):before {
//       color: #000;
//       content: attr(placeholder);
//     }

//     input[type="date"] {
//       -webkit-text-fill-color: #000;
//       -webkit-opacity: 1;
//     }
//   }
// }

// PAYMENT
// #root {
//   display: flex;
//   align-items: center;
// }

#payment-form {
    width: 100%;
    // min-width: 500px;
    align-self: center;
    box-shadow:
        0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
        0px 2px 5px 0px rgba(50, 50, 93, 0.1),
        0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
}
.StripeElement {
    width: 100%;
    padding-bottom: 20px;
}

#payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
    margin-bottom: 20px;
}

#payment-element {
    margin-bottom: 24px;
}

/* Buttons and links */
#payment-form button {
    background: #0583f2;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
}

#payment-form #payment-formbutton:hover {
    filter: contrast(115%);
}

#payment-formbutton:disabled {
    opacity: 0.5;
    cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
    border-radius: 50%;
}

.spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.spinner:before,
.spinner:after {
    position: absolute;
    content: "";
}

.spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 600px) {
    form {
        width: 100%;
        min-width: initial;
    }
}

.page-submit {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    min-height: 350px;
    background: #ffffff;
    background-blend-mode: luminosity;
    color: var(--secondary-darkblue);
    margin: 0 auto;
    margin-bottom: 50px;
}
.page-submit {
    margin-top: 180px;
    //min-width: 1200px;
}

.page-submit {
    flex-grow: 1;
}

.page-submit p {
    line-height: 20px;
    font-size: 16px;
    font-weight: 400;
    font-family: "Barlow", sans-serif;
    color: #19274c;
}
.page-submit p strong {
    line-height: 24px;
    font-size: 20px;
    font-weight: 600;
    font-family: "Barlow", sans-serif;
    color: #0583f2;
}
.page-submit h1,
.page-submit h2,
.page-submit h3,
.page-submit h4,
.page-submit h5,
.page-submit h6 {
    margin-top: 2rem;
    font-family: "Barlow", sans-serif;
    color: #1f57a0;
    font-weight: 700;
}
.page-submit h1 {
    font-weight: 300;
}
.page-submit h3 {
    font-size: 24px;
}
.page-submit h4 {
    font-family: "Barlow", sans-serif;
    font-weight: 300;
    font-size: 36px;
    line-height: 44px;
    margin-top: 0px;
    color: #1f57a0;
}
.page-submit hr {
    margin: 1.5rem 0;
}
.page-submit ol {
    list-style-type: lower-alpha;
}
.page-submit ol li {
    margin-top: 0.25rem;
    font-family: "Barlow", sans-serif;
    color: #1f57a0;
}

.page-submit form input:hover:enabled {
    background: #eef0f4;
    border: 2px solid #eef0f4;
    text-decoration-line: underline;
    color: #19274c;
}

.page-submit form label {
    color: #eef0f4;
    width: 100%;
    flex-direction: column;
    display: flex;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 600;
}
.page-submit form label input {
    // width: 262px;
    height: 35px;
    background: #eef0f4;
    border: 2px solid #eef0f4;
    text-decoration-line: underline;
    color: #19274c;
    text-decoration: none;
}

.page-submit .submitFormWrapper {
    background-color: var(--secondary-darkblue);
    padding: 40px;
    //  width: 776px;

    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    //  flex: 1 0 100%;
    flex-wrap: wrap;
    //  margin-top: calc(var(--bs-gutter-y) * -1);
    //  margin-right: calc(var(--bs-gutter-x)/ -2);
    //  margin-left: calc(var(--bs-gutter-x)/ -2);
}
.page-submit form input {
    margin-top: 5px;
}
.page-submit form input:disabled {
    opacity: 50%;
}
.page-submit form input:hover:enabled {
    background: #eef0f4;
    border: 2px solid #eef0f4;
    text-decoration-line: underline;
    color: #19274c;
}
.page-submit form .formrow {
    margin: 0px;
    padding: 0px;
}
.page-submit form label select {
    height: 36px;
    padding: 0px;
    margin-top: 5px;
}

.page-submit form label input[type="file"] {
    height: 68px;
    margin: 0px;
    margin-top: 10px;
}
.page-submit form label textarea {
    padding: 14px;
    margin: 0px;
    margin-top: 10px;
}
.page-submit form .daysCheckbox {
    margin: 0px;
    padding: 0px;
    flex-direction: row;
    justify-content: space-evenly;
}

.page-submit form .daysCheckbox label {
    color: #d9d9d9;
    width: 100%;
    flex-direction: column;
    display: flex;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 20px;
    font-size: 10px;
    padding-top: 5px;
}
.page-submit form .daysCheckbox input[type="checkbox"] {
    width: 30px;
    height: 30px;
}
.prmotitle h3 {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #1f57a0;
}
.submit-description {
    padding-left: 0px !important;
}

@media only screen and (max-width: 991px) {
    .preview-cards {
        display: none;
    }
}

.page-submit .inputNote {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    // display: flex;
    align-items: center;
    color: #eef0f4 !important;
    margin-top: 3px;
}

.inputNoteRight {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    // display: flex;
    align-items: center;
    color: #eef0f4;
    margin-top: 3px;
}
.inputNote span {
    font-weight: 700;
}
.fullNote {
    display: inline-flex;
    justify-content: space-between;
}
.rederror {
    color: #e74c3c;
}
.paymentHeader {
    display: flex;
    flex-direction: column;
    padding: 20px 40px 0px 40px;
}
.page-submit .paymentPrice {
    color: #fff !important;
    font-size: 50px;
    margin-top: 0px;
}
.page-submit .paymentTitle {
    color: #fff !important;
    margin-bottom: 0px;
    font-weight: 700;
}
.page-submit .paymentDescription {
    color: #fff !important;
    font-size: 14px;
}
.maxInput {
    max-width: 200px;
}

// .mobilePreview{max-width: 390px;}

.news-card-data {
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #1f57a0;
    border-radius: 0px 0px 8px 8px;
}

.pageTitle {
    display: flex;
    justify-content: center;
    align-items: center;
}
.pageTitle h1 {
    display: flex;
    font-size: 20px;
    font-weight: 700;
}
.pageTitleMobile {
    display: flex;
    font-size: 20px;
    font-weight: 700;
}

@media screen and (max-width: 767px) {
    .pageTitle {
        display: none;
    }
    .pageTitleMobile {
        display: flex;
        font-size: 20px;
        font-weight: 700;
    }
    .pageTitle h1 {
        padding-right: 0px;
    }
}
.pageContentContact h1 {
    font-size: 20px;
    font-weight: 700;
}

//news
.news-card {
    margin-bottom: 88px;
}
.news-card-img {
}
.news-card-img img {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
}

.news-card-data {
    padding: 25px;
    position: relative;
}

.news-card-data h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-top: 0px;

    color: #1f57a0;
}
.news-card-data img {
    display: flex;
    position: absolute;
    bottom: 18px;
}
.news-card-data p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-top: 10px;
    color: #19274c;
}
.articleSec {
    padding-top: 38px;
    padding-bottom: 38px;
}
.backToResources {
    padding-bottom: 0px;
}
.articleSec a {
    color: #1f57a0;
    font-weight: 600;
}
.articleWrapper {
    padding-top: 100px;
    padding-bottom: 100px;
}

.articleImg {
    width: 100%;
    border-radius: 8px;
}
.articleContent p {
    line-height: 28px;
    font-size: 18px;
}
.articleWrapper p b {
    color: #0583f2;
}

.articleContent a {
    color: #fa6900;
    text-decoration: underline;
    font-weight: bolder;
}
.articleContent a:hover {
    text-decoration: underline;
    color: #fa6900;
}
.articleContent a b {
    color: #fa6900;
    text-decoration: underline;
}
.articleContent h1 {
    color: #1f57a0;
}
.articleContent h2 {
    font-weight: 400;
    color: #1f57a0;
    margin-top: 28px;
}
.articleContent h3 {
    color: #1f57a0;
}
.articleContent h4 {
    color: #1f57a0;
}
.articleContent h5 {
    color: #1f57a0;
}
.articleContent h6 {
    color: #1f57a0;
}

.articleSearch {
    width: 100%;
    padding: 30px;
    background: #eef0f4;
    border: 1px solid #1f57a0;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}
.articleSearch h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #1f57a0;
    margin-bottom: 24px;
}
.articleSearch form {
    width: 100%;
}
.articleSearch form .input-field {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    color: #19274c;
}

.articleEmail {
    width: 100%;
    padding: 30px;
    background: #eef0f4;
    border: 1px solid #1f57a0;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}
.articleEmail h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #1f57a0;
    margin-bottom: 24px;
}
.articleEmail form {
    width: 100%;
}
.articleEmail form .input-field {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    color: #19274c;
}

.articleSearchMobile {
    width: 100%;
    padding: 15px;
    background: #eef0f4;
    border: 0px solid #1f57a0;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    display: none;
}
.articleSearchMobile h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #1f57a0;
    margin-bottom: 8px;
}
.articleSearchMobile form {
    width: 100%;
}
.articleSearchMobile form .input-field {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    color: #19274c;
}

@media screen and (max-width: 1199px) {
    .articleWrapper {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}
@media screen and (max-width: 767px) {
    .articleWrapper {
        padding-top: 0px;
    }
    .articleSec {
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .articleSec h1 {
        font-size: 30px;
        line-height: 38px;
    }
    .articleImg {
        margin-top: 10px;
    }
    .relatedNews {
        margin-top: 0;
    }
    .articleWrapper {
        padding-bottom: 40px;
    }
}

.noCampEmailForm {
    width: 100%;
    padding: 10px;
    background: #eef0f4;
    // border: 1px solid #1F57A0;
    // border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.noCampEmailForm h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #1f57a0;
    margin-bottom: 24px;
}
.noCampEmailForm form {
    width: 100%;
    // max-width: 500px;
}
.noCampEmailForm form .input-field {
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 5px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    color: #19274c;
}
.formZip {
    max-width: 160px;
}
.noCampEmailForm button {
    height: 50px;
    color: #fff;
    background-color: #1f57a0;
    border-radius: 8px;
    // border-bottom-right-radius: 8px;
    // border-top-right-radius: 8px;
    border: #19274c;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    text-decoration: none;
    padding-top: 12px;
    width: 124px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-left: 5px;
}
.articleMainSection {
    border-top: 1px solid #d9d9d9;
}

@media screen and (max-width: 1199px) {
    .articleSearchMobile {
        display: flex;
    }
    .articleSearch {
        display: none;
    }
    .articleMainSection {
        border-top: 0px solid #d9d9d9;
    }
}

#location-search {
    display: inline-flex;
    width: 100%;
}
#location-search .btnSeearch {
    height: 50px;
    color: #fff;
    background-color: #1f57a0;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border: #19274c;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    text-decoration: none;
    padding-top: 10px;
    width: 124px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

#location-search .input-field {
    font-size: 16px;
    // margin-top: 5px;
    // margin-bottom:5px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    // border-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    color: #19274c;
    padding-left: 36px;
    border-right: 0px;
}

#home-form {
    display: flex;
    width: 100%;
    flex-direction: row;
}
@media screen and (max-width: 1199px) {
    #home-form {
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
    .formZip {
        max-width: 100%;
    }
}
@media screen and (max-width: 1199px) {
    #home-form {
        flex-direction: row;
    }
    .formZip {
        max-width: 120px;
    }
}
@media screen and (max-width: 767px) {
    #home-form {
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
    .formZip {
        max-width: 100%;
    }
}

#home-form .btnSeearch {
    height: 50px;
    color: #fff;
    background-color: #1f57a0;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border: #19274c;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    text-decoration: none;
    padding-top: 10px;
    width: 124px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}
#home-form .input-field {
    font-size: 16px;
    // margin-top: 5px;
    // margin-bottom:5px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    // border-top-left-radius: 8px;
    // border-bottom-left-radius: 8px;
    // border-bottom-right-radius: 0px;
    // border-top-right-radius: 0px;
    color: #19274c;
    padding-left: 8px;
    border-right: 0px;
    margin-left: 5px;
    margin-left: 5px;
}

.mainArticle {
    display: flex;
}
.social {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}
@media screen and (max-width: 1199px) {
    .social {
        display: none;
    }
    // .mainArticle{flex-direction: column-reverse;}
    .mainArticle .articleSearch {
        margin-bottom: 34px;
    }
}
.socialHeadline {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #1f57a0;
}
.headlineWrapper {
    display: flex;
    align-items: center;
}

.socialShare {
    width: 35px;
    margin-bottom: 10px;
}

.relatedNews {
    margin-top: 43px;
    padding-top: 33px;
    border-top: 1px solid #d9d9d9;
}
.relatedTitle {
    margin-bottom: 24px;
}
.relatedTitle h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    /* identical to box height */

    color: #19274c;
}
.relatedNews a {
    text-decoration: none;
}
.relatedNews a:hover {
    text-decoration: underline;
}

.row-flex {
    display: flex;
    flex-wrap: wrap;
}

.news-card {
    margin-bottom: 30px;
}
.news-card-data {
    height: 260px;
    padding: 20px 20px 10px;
    color: #fff;
}
@media (max-width: 1199px) {
    .news-card-data {
        height: 305px;
    }
}
@media (max-width: 992px) {
    .news-card-data {
        height: 285px;
    }
}
@media (max-width: 767px) {
    .news-card-data {
        height: 390px;
    }
}
@media (max-width: 575px) {
    .news-card-data {
        height: auto;
    }
    .news-card-data p {
        padding-bottom: 32px;
    }
}
.newsBack {
    text-decoration: none;
}
.newsBack:hover {
    text-decoration: underline;
}
.back {
    margin-right: 10px;
}

.no-results {
    background: #eef0f4;
    border: 1px solid #0583f2;
    border-radius: 8px;
    text-align: center;
    padding-top: 23px;
    padding-left: 52px !important;
    padding-right: 52px !important;
    padding-bottom: 20px;
    margin-top: 60px;
    // margin: 10px;
    // width: 100%;
}

.no-results h2 {
    color: #1f57a0;
    margin-bottom: 28px;
    font-size: 24px;
    font-weight: 700;
}
.no-results h2 span {
    color: #f26120;
}
.no-results h2 span {
    font-weight: 600 !important;
    color: #f26120;
}

.no-results p {
    font-size: 18px;
    margin-bottom: 32px;
    line-height: 24px;
}
.no-results strong {
    color: #1f57a0;
}
.no-results p a {
    color: #f26120;
}

@media (max-width: 1044px) {
    .no-results {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
@media (max-width: 461px) {
    .no-results {
        display: flex;
        flex-direction: column;
    }
}
.thankyouEmail {
    margin-top: 10px;
}
.thankyouEmail p {
    font-size: 15px;
    line-height: 18px;
    color: #4d9b09;
    font-weight: 600;
}

.linkOrange {
    color: #f26120;
}
.linkBlue {
    font-weight: 600 !important;
    color: #0583f2;
}

.borderBtn {
    background: #fff;
    border: 2px solid #1f57a0;
    border-radius: 7px;
    padding: 7px 14px;
    color: #1f57a0;
    text-decoration: none;
    margin-left: 25px;
    margin-right: 25px;
}
.borderBtn:hover {
    text-decoration: underline;
    color: #1f57a0;
}

.orangeBtn {
    background: #f26120;
    border: 2px solid #f26120;
    border-radius: 7px;
    padding: 7px 14px;
    color: #fff;
    text-decoration: none;
    margin-left: 25px;
    margin-right: 25px;
}
.orangeBtn:hover {
    text-decoration: underline;
    color: #fff;
}

.blueBtn {
    background: #eef0f4;
    border: 2px solid #1f57a0;
    border-radius: 7px;
    padding: 7px 12px;
    color: #1f57a0;
    text-decoration: none;
    margin-left: 25px;
    margin-right: 25px;
}
@media (max-width: 1044px) {
    .blueBtn {
        margin-bottom: 30px;
    }
}

.blueBtn:hover {
    text-decoration: underline;
}

.resourceLink {
    color: #19274c;
    text-decoration: underline;
}

.resourceLink b {
    color: #fa6900;
    text-decoration: underline;
}

.campAd {
    width: 100%;
    padding: 15px;
    background: #1f57a0;
    border: 1px solid #1f57a0;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    text-decoration: none;
    color: #fff;
}
.campAd h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    /* margin-bottom: 24px; */
}

.adcardimg img {
    max-width: 100%;
    margin-bottom: 20px;
}

.adcardimg p {
    color: #fff;
}

.cardAdTitle {
    // font-family: 'Barlow';
    // text-decoration: none;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #fff;
    span {
        font-weight: 400;
        color: #19274c;
    }
    &:hover {
        text-decoration: underline;
    }
}
.cardAdTitleAd {
    // font-family: 'Barlow';
    // text-decoration: none;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #fff;
    span {
        font-weight: 400;
        color: #19274c;
    }
}
.addescriptiontext {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #fff !important;
    margin-bottom: 45px;
}
.addescriptiontext p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #fff !important;
    margin-bottom: 45px;
}

.addistance {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    margin-bottom: 10px !important;
}

.adpromotedtext {
    position: relative;
    float: right;
    right: 12px;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #fff;
}
.promoTextSection {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 8px;
}
.promoTextSectionAd {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    /* position: absolute; */
    bottom: 8px;
}
.promoTextSection a {
    color: #fff !important;
    text-align: left !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    // text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
.promoTextSectionAd a {
    color: #fff !important;
    text-align: left !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    // text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
.promotedtextHome {
    color: #fff !important;
    text-align: right !important;
    font-weight: 400 !important;
    font-size: 11px !important;
    line-height: 13px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 8px;
    padding-right: 5px;
}

.formSubmit {
}

.formSubmit {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    border: 2px solid #eef0f4;
    border-radius: 7px;
    background: #1f57a0;
    padding: 14px 30px;
    margin-bottom: 10px;
    text-decoration: none;
    float: right;
    width: 124px;
}

.formSubmit:hover {
    //pointer-events: none;
    background: #0583f2;
}

.inlineArticle {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: start;
    // margin-bottom: 30px;
    border-radius: 8px;
    border: 1px solid #0583f2;
    background: #eef0f4;
    padding: 18px;
    text-decoration: none !important;
    position: relative;
}

.inlineArticleImg img {
    width: 243px;
    text-decoration: none;
}
.inlineArticle .inlineArticleContent {
    padding-left: 18px;
    text-decoration: none;
}
.inlineArticleContent h3 {
    color: #1f57a0;
    font-family: Barlow;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
}
.inlineArticleContent p {
    color: #19274c;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    padding-bottom: 16px;
}
.inlineArticleContent img {
    width: 32px;
    position: absolute;
    bottom: 20px;
}

@media (max-width: 575px) {
    .inlineArticle {
        flex-direction: column;
    }
    .inlineArticle .inlineArticleContent {
        padding-left: 0px;
    }
    .inlineArticleImg img {
        width: 100%;
        margin-bottom: 20px;
    }
    .inlineArticleContent p {
        margin-bottom: 26px;
    }
}

.noCampEmail {
    margin-top: 40px;
    border-top: 1px solid #1f57a0;
    padding-top: 40px;
}
.resourcard {
    text-decoration: none;
    display: inline-flex;
}
.resourcard:hover {
    // text-decoration: underline;
    color: #fff;
}

.providerCard {
    // padding-left: 0px;
    // padding-right: 0px;
    // border-radius: 8px;
    // // border: 1px solid #0583F2;
    // background: #1F57A0;
    // padding: 15px;
    // margin-bottom: 15px;
}
.providercardLocation {
    color: #fff;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.providercardLocation span {
    font-size: 14px;
    font-weight: 300;
}
// @media screen and (min-width: 768px) {
.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}
// }
.providercardimg {
    width: 225px;
    height: 225px;
    min-width: 225px;
    margin-right: 20px;
}

.providercardimg div {
    overflow: hidden;
    // width: auto;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.providerHeader {
    display: flex;
    justify-content: space-between;
}
.providerHeader p {
    color: #eef0f4;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 0px !important;
    padding-right: 3px;
    min-width: 100px;
    text-align: right;
}
.providerCardWrapper {
    display: flex;
    border-radius: 8px;
    // border: 1px solid #0583F2;
    background: #1f57a0;
    padding: 15px;
    margin-bottom: 15px;
}
.mainLocation {
    padding-top: 8px;
}

.resourcardDemo {
    display: flex;
    flex-direction: column;
    // text-decoration: none;
}
.providercardimgDemo {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    padding-bottom: 56.25%;
    height: 0px;
}
.providerImgDemo {
    height: 100%;
    width: 100%;
    position: absolute;
    background-repeat: none;
    background-size: cover;
}
.promoBtnType {
    border-radius: 8px;
    border: 1px solid #1f57a0;
    background: #f5f5f5;
    color: #1f57a0;
    text-align: center;
    font-family: Barlow;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 5px 15px;
    margin-top: 20px;
}

@media screen and (max-width: 767px) {
    .resourcard {
        display: flex;
        flex-direction: column;
    }
    .providercardimg {
        width: 100%;
        margin-bottom: 20px;
        position: relative;
        padding-bottom: 56.25%;
        height: 0px;
    }
    .providerImg {
        height: 100%;
        width: 100%;
        position: absolute;
        background-repeat: none;
        background-size: cover;
    }

    .threepromo {
        // margin-top: 20px;
    }
}
.campcardPromo .specialNeeds {
    margin-bottom: 10px;
}
.campcard .specialNeeds {
    margin-bottom: 8px;
}

.specialNeeds {
    color: #fff;
    margin-bottom: 10px;
}

.adTable {
    border: #1f57a0;
    border-radius: 20px;
}
.adTable {
    border-radius: 20px;
}
tbody,
td,
tfoot,
th,
thead,
tr {
    padding: 10px 20px;
}

th {
    width: 25%;
}
.tableDescription {
    background-color: #eef0f4;
    vertical-align: top;
}
.tableDescription td {
    vertical-align: top;
}
.tableBold {
    color: #19274c;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}
@media screen and (max-width: 767px) {
    .tableDescription {
        display: none;
    }
    tbody,
    td,
    tfoot,
    th,
    thead,
    tr {
        padding: 6px 6px;
    }
    th {
        font-size: 14px;
    }
    td {
        font-size: 14px;
    }
    .tableBold {
        font-size: 14px;
    }
}

.tableHeader {
    background-color: #1f57a0 !important;
    color: #fff !important;
    text-align: center;
}
thead tr th:last-child {
    background-color: #1f57a0 !important;
    color: #fff !important;
    border-top-right-radius: 10px;
    border: 1px solid #1f57a0;
}
thead tr th:first-child {
    background-color: #1f57a0 !important;
    color: #fff !important;
    border-top-left-radius: 10px;
    border: 1px solid #1f57a0;
}
.adTable tbody tr:nth-child(10) {
    background-color: rgba(31, 87, 160, 0.08);
}
table.rounded-corners .campTableRow td {
    border-bottom: 1px solid #eaecf0;
}

.adTitle {
    text-align: center;
}

.video-responsive-advertise {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive-advertise iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.compoent-content {
    padding-top: 20px;
    padding-bottom: 80px;
}
.compoent-content > p {
    color: #19274c;
    font-family: Barlow;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

table.rounded-corners {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
    border: 1px solid #1f57a0;
    padding: 0px;
}

/* Apply a border to the right of all but the last column */
table.rounded-corners td:last-child {
    border-left: 1px solid #1f57a0;
}
table.rounded-corners td:first-child {
    border-right: 1px solid #1f57a0;
}

/* Apply a border to the bottom of all but the last row */
table.rounded-corners > thead > tr:not(:last-child) > th,
table.rounded-corners > thead > tr:not(:last-child) > td,
table.rounded-corners > tbody > tr:not(:last-child) > th,
table.rounded-corners > tbody > tr:not(:last-child) > td,
table.rounded-corners > tfoot > tr:not(:last-child) > th,
table.rounded-corners > tfoot > tr:not(:last-child) > td,
table.rounded-corners > tr:not(:last-child) > td,
table.rounded-corners > tr:not(:last-child) > th,
table.rounded-corners > thead:not(:last-child),
table.rounded-corners > tbody:not(:last-child),
table.rounded-corners > tfoot:not(:last-child) {
    // border-bottom: 1px solid black;
}
.tableMark {
    text-align: center;
    font-weight: bold;
}

.testheader {
    margin-top: 200px;
}
.adDemoCard {
    border-radius: 8px;
    background: #eef0f4;
    margin-top: 20px;
    margin-bottom: 20px;
}
.adDemos {
    margin-bottom: 40px;
}

.adCardMainHeader {
    color: #19274c;
    text-align: left;
    font-family: Barlow;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 100% */
    margin-bottom: 46px;
}
.ad-content {
    margin-top: 70px;
    margin-bottom: 70px;
}
.pageContentAdvertising {
    margin-bottom: 56px;
}
.pageContentAdvertisingContent {
    margin-bottom: 70px;
}
.pageContentAdvertisingContent .adBtns {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    width: 100%;
    margin-bottom: 40px;
}
.pageContentAdvertising p,
.pageContentAdvertisingContent p {
    color: #19274c;
    font-family: Barlow;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.pageContentAdvertising p strong,
.pageContentAdvertisingContent p strong {
    color: #0583f2;
    font-family: Barlow;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.pageContentAdvertising a {
    color: #fa6900;
    font-family: Barlow;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.pageContentAdvertising h1 {
    color: #1f57a0;
    text-align: center;
    font-family: Barlow;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 5px;
}
.pageContentAdvertising h2 {
    color: #0583f2;
    text-align: center;
    /* H1 (Desktop) */
    font-family: Barlow;
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: 44px;
    margin-bottom: 20px;
}

.providerCard {
    // padding-left: 0px!important;
    // padding-right: 0px!important;
}

.ytp-title-link {
    display: none !important;
}

.promoLocationsList {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 0px;
    padding-top: 8px;
    margin-bottom: 0px;
}
.promoLocationsList:hover {
    cursor: pointer;
}
.promoLocationsListLinks {
    padding-left: 0rem !important;
    padding-top: 6px;
}
.promoLocationsListLinks li {
    list-style: none;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    padding-bottom: 10px;
}
.promoLocationsListLinks li span {
    font-size: 14px;
    font-weight: 300;
}

.promoLocationsListLinks li a {
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    padding-bottom: 10px;
}

.sitemap-list ul {
    list-style: none;
    padding-top: 50px;
    padding-left: 0rem !important;
    -webkit-column-count: 4; /* Chrome, Safari, Opera */
    -moz-column-count: 4; /* Firefox */
    column-count: 4;
}
.sitemap-list ul li a {
    font-size: 16px;
    color: #1f57a0;
}

.sitemap-list h1 {
    font-family: Barlow, sans-serif;
    color: #1f57a0;
    font-size: 36px;
    font-weight: 300;
    line-height: 44px;
}
@media screen and (max-width: 991px) {
    .sitemap-list ul {
        -webkit-column-count: 2; /* Chrome, Safari, Opera */
        -moz-column-count: 2; /* Firefox */
        column-count: 2;
    }
}

.campStandardCard {
    // display: none;
}

.campCardSection {
    // display: none;
}

.marketingSection {
    margin-bottom: 80px;
}

.marketing-links {
    display: flex;
    flex-direction: column;
    position: sticky;

    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 118px;
    z-index: 1; /* Ensure it stays above other content */
    background-color: white; /* Optional: to ensure it doesn't overlap with other content */
    h3 {
        font-size: 18px;
        font-weight: 600;
    }
}

.marketing-links button {
    width: 100%;
    padding-bottom: 0px;
    margin-bottom: 10px;
    background: none;
    border: none;
    text-align: left;
    font-size: 18px;
    padding-left: 0px;
    width: fit-content;
    &:hover {
        text-decoration: none;
        border-bottom: 3px solid #1f57a0;
    }
}

.orangeDevider {
    border: 2px solid #f26120;
    width: 77px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.marketingHeader {
    // padding-top: 40px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px !important;

    h2 {
        font-family: Barlow;
        font-size: 28px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        text-transform: uppercase;
    }
    p {
        font-family: Barlow;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
    }
}
.marketingSectionSpace {
    margin-top: 40px !important;
}
.marketingCardOne {
    padding-top: 76px;
    @media (max-width: 1199px) {
        padding-top: 104px;
    }
    @media (max-width: 767px) {
        padding-top: 0px;
    }
}

.marketingCard {
    border: 1px solid #1f57a0;
    border-radius: 5px;
    padding: 0px !important;
    .cardTitleFeatured {
        padding: 20px 14px;
        // margin-bottom: 20px;
        background-color: #cfdcf4;
        display: inline-flex;
        width: 100%;
        @media (max-width: 1199px) {
            height: 92px;
        }
        @media (max-width: 767px) {
            height: auto;
        }
    }
    .marketingCardFeaturedText {
        padding: 10px;
        min-height: 160px;
        @media (max-width: 767px) {
            min-height: auto;
        }
    }
    h3 {
        margin-bottom: 0px;
        font-family: Barlow;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
    }
    p {
        //styleName: p (Desktop);
        font-family: Barlow;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
    }
}
.marketingCardWrapper,
.marketingCardWrapperTwo {
    margin-bottom: 30px;
}

.marketingCardSectionTwo {
    background-color: rgba(31, 87, 160, 0.08);
    border-radius: 5px;
    padding-top: 20px;
    padding-bottom: 10px;
}
.sectoionTwoHeader {
    font-family: Barlow;
    color: #1f57a0;

    font-size: 18px;
    font-weight: 800;
    line-height: 28px;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 20px;
}

.marketingCardTwo {
    border: 1px solid #1f57a0;
    border-radius: 5px;
    padding: 0px !important;
    .cardTitleFeatured {
        padding: 20px 14px;
        // margin-bottom: 20px;
        background-color: #cfdcf4;
        display: inline-flex;
        width: 100%;
        @media (max-width: 1199px) {
            height: 92px;
        }
        @media (max-width: 767px) {
            height: auto;
        }
        .cardImg {
            height: 25px;
        }
    }
    .marketingCardFeaturedText {
        min-height: 160px;
        padding: 10px;
        @media (max-width: 767px) {
            min-height: auto;
        }
    }
    .cardTitleFeatured {
        background-color: #fff;
    }
    h3 {
        color: #1f57a0;
        margin-bottom: 0px;
        font-family: Barlow;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
    }
    .marketingCardFeaturedText {
        background-color: #1f57a0;
    }
    p {
        //styleName: p (Desktop);
        font-family: Barlow;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: #fff;
    }
}
.features-benefits {
    margin-bottom: 60px !important;
    // padding-top: 118px!important;
}
.platform {
    margin-bottom: 60px !important;
    padding-top: 118px !important;
}
.join-now {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px !important;
    background-color: #f26120;
    color: #fff;
    border-radius: 8px;
    // margin-bottom: 30px!important;
    margin-top: 60px !important;
    .joinbtn {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-left: 32px;
        p {
            display: flex;
            justify-content: center;
        }
    }

    .cta-submit {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #f26120;
        border: 2px solid #eef0f4;
        border-radius: 7px;
        background: #fff;
        padding: 12px 12px;
        margin-bottom: 10px;
        text-decoration: none;
        text-align: center;
    }
    h2 {
        color: #fff;
    }
    p {
        color: #fff;
        margin-bottom: 0px;
    }
}

.stats {
    margin-bottom: 60px !important;
    padding-top: 118px !important;
}
.ben-table {
    margin-bottom: 60px !important;
    padding-top: 10px !important;
}

.examples {
    margin-bottom: 60px !important;
    // padding-top: 0px!important;
}

.marketing-faq {
    margin-bottom: 60px !important;
    padding-top: 10px !important;

    padding-bottom: 200px;
}

.sectionTitle {
    color: #1f57a0;
}
.sectionCopy {
    color: #19274c;
}
.hearoImg {
    img {
        width: 100%;
    }
}

.marketingStats {
    margin-bottom: 60px !important;
    margin-top: 60px !important;
    padding: 40px !important;

    background-color: rgba(31, 87, 160, 0.08);
}
.statNum {
    font-family: Barlow;
    font-size: 96px;
    font-weight: 300;
    line-height: 128px;
    text-align: center;
    color: #0583f2;
}

.statView {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.statTitle {
    font-family: Barlow;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    color: #1f57a0;
}

.marketingFaq {
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px !important;

    h2 {
        font-family: Barlow;
        font-size: 28px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        text-transform: uppercase;
    }
    p {
        font-family: Barlow;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
    }
}

.marketingList {
    padding-left: 0; /* Remove default padding */
    list-style: none;
}

.marketingList li {
    position: relative; /* Positioning context for pseudo-element */
    margin: 20px 0; /* Space between items */
    padding-left: 40px; /* Space for the circle */
    z-index: -1;
    h4 {
        font-family: Barlow;
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
        text-align: left;
        color: #0583f2;
    }
    p {
        color: #19274c;
    }
}

.marketingList li::before {
    content: counter(item); /* Show the item number */
    counter-increment: item; /* Increment the counter */
    position: absolute;
    left: 4px; /* Align to the left with some padding */
    top: 10px; /* Center vertically */
    transform: translateY(-50%); /* Adjust for vertical centering */
    width: 26px; /* Circle diameter */
    height: 26px; /* Circle diameter */
    background-color: #f26120; /* Circle color */
    border-radius: 50%; /* Make it a circle */
    display: flex; /* Flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    color: white; /* Text color */
    font-weight: bold; /* Optional: make the number bold */
    z-index: 1; /* Place in front of the text */
}

/* Initialize the counter */
.marketingList {
    counter-reset: item; /* Reset counter for ordered list */
}

.markeingLink {
    font-family: Barlow;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: center;
    color: #1f57a0;
    padding-left: 40px;
    img {
        height: 12px;
        padding-left: 10px;
    }
}

.cardImg {
    height: 30px;
    margin-right: 15px;
}

@media screen and (max-width: 991px) {
    .marketing-links {
        display: none;
    }
    .steps {
        padding-top: 40px;
    }
}

.videoSec {
    flex-direction: row;
    @media (max-width: 991px) {
        flex-direction: column-reverse;
        .steps {
            padding-bottom: 40px;
        }
    }
}

.marketing-links button.active {
    border-bottom: 3px solid #1f57a0;
}
